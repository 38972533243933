import React from "react"

const About = () => (
	<div style={{ color: `teal` }}>
		<h1>About Gatsby</h1>
		<p>Such wow. Very React.</p>
	</div>
)

export default About
